// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useCallback, useEffect, useRef } from 'react';

interface MetaPixelOptions {
  userEmail?: string;
}

export const useMetaPixel = (metaPixelId: string) => {
  const ref = useRef(false);

  useEffect(() => {
    if (!metaPixelId) {
      console.warn('Please insert pixel id for initializing');
    }

    const init = (f, b, e, v, n, t, s) => {
      if (f.fbq) return;
      n = f.fbq = function () {
        if (n.callMethod) {
          n.callMethod.apply(n, arguments);
        } else {
          n.queue.push(arguments);
        }
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      if (s && s.parentNode) s.parentNode.insertBefore(t, s);
    };
    init(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js',
    );
  }, [metaPixelId]);

  const boot = useCallback(
    ({ userEmail }: MetaPixelOptions = {}) => {
      if (ref.current) return;

      const additionalData = {
        ...(userEmail && { em: userEmail, external_id: userEmail }),
      };

      fbq('init', metaPixelId, additionalData);
      fbq('track', 'PageView');
      ref.current = true;
    },
    [metaPixelId],
  );

  return { boot };
};
