import { isRouteErrorResponse } from '@remix-run/react';
import { lazy } from 'react';

const ErrorBoundary400 = lazy(() =>
  import('./ErrorBoundary400').then((module) => ({
    default: module.ErrorBoundary400,
  })),
);
const ErrorBoundary403 = lazy(() =>
  import('./ErrorBoundary403').then((module) => ({
    default: module.ErrorBoundary403,
  })),
);
const ErrorBoundary404 = lazy(() =>
  import('./ErrorBoundary404').then((module) => ({
    default: module.ErrorBoundary404,
  })),
);
const ErrorBoundary422 = lazy(() =>
  import('./ErrorBoundary422').then((module) => ({
    default: module.ErrorBoundary422,
  })),
);

const ErrorBoundary500 = lazy(() =>
  import('./ErrorBoundary500').then((module) => ({
    default: module.ErrorBoundary500,
  })),
);

const getErrorBoundary = (error: unknown) => {
  const isRouteError = isRouteErrorResponse(error);
  if (!isRouteError) return <ErrorBoundary500 />;

  switch (error.status) {
    case 400:
      return <ErrorBoundary400 />;
    case 403:
      return <ErrorBoundary403 />;
    case 404:
      return <ErrorBoundary404 />;
    case 422:
      return <ErrorBoundary422 />;
    case 500:
    default:
      return <ErrorBoundary500 />;
  }
};

interface IErrorBoundaryProps {
  error: unknown;
}

const ErrorBoundaryHN = ({ error }: IErrorBoundaryProps) => {
  return getErrorBoundary(error);
};

export { ErrorBoundaryHN };
