import type {
  HeadersFunction,
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from '@remix-run/node';
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react';
import { withTheme } from '@hn-ui/themes';
import {
  withSentry,
  captureRemixErrorBoundaryError,
  setContext,
} from '@sentry/remix';
import {
  styled,
  StyleSheetManager,
  createGlobalStyle,
} from 'styled-components';
import { getClientEnv } from './utils/env.server';
import path from 'path-browserify';
import { ErrorLayout } from '@components/layouts/error-layout';
import {
  commitSession,
  getSession,
  getUser,
  getUserInfo,
} from './utils/session.server';
import type { TUserRole } from '@hn-front/data-types';
import { useEffect } from 'react';
import { useMetaPixel } from './hooks/useMetaPixel';
import isPropValid from '@emotion/is-prop-valid';
import { ErrorBoundaryHN } from '@components/error-boundaries';
import { createCSRFToken } from './utils/utils.server';
import { useNonce } from './utils/nonce-provider';

export const meta: MetaFunction = () => [
  {
    title:
      'Math Centers that Deliver — Differentiation Done Right | HappyNumbers.com',
  },
  {
    charset: 'utf-8',
  },
  {
    name: 'viewport',
    content: 'width=device-width,initial-scale=1',
  },
  {
    property: 'og:site_name',
    content: 'HappyNumbers.com',
  },
  {
    property: 'og:url',
    content: 'https://happynumbers.com/',
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    name: 'twitter:card',
    content: 'summary_large_image',
  },
  {
    name: 'twitter:image',
    content: 'https://static.happynumbers.com/main-page/preview.png',
  },
  {
    name: 'twitter:site',
    content: '@happynumbers',
  },
  {
    name: 'twitter:site:id',
    content: '1548372793',
  },
  {
    name: 'twitter:creator',
    content: '@happynumbers',
  },
  {
    name: 'twitter:domain',
    content: 'happynumbers.com',
  },
  {
    name: 'format-detection',
    content: 'telephone=no',
  },
  {
    name: 'facebook-domain-verification',
    content: 'ped12a4sv8uu9joyewvztt09lcakba',
  },
];

export const links: LinksFunction = () => {
  return [
    {
      rel: 'icon',
      href: path.join(
        import.meta.env.VITE_TEACHERS_BASE,
        '/teacher-assets/favicons/favicon.svg',
      ),
      type: 'image/x-icon',
    },
    {
      rel: 'dns-prefetch',
      href: 'https://s3.us-west-1.amazonaws.com',
    },
    {
      rel: 'preconnect',
      href: 'https://s3.us-west-1.amazonaws.com',
    },
    {
      rel: 'dns-prefetch',
      href: 'https://fonts.googleapis.com',
    },
    {
      rel: 'preconnect',
      href: 'https://fonts.googleapis.com',
    },
    {
      rel: 'preconnect',
      href: 'https://fonts.gstatic.com',
      crossOrigin: 'anonymous',
    },
    {
      rel: 'preconnect',
      href: 'https://s3.us-west-1.amazonaws.com',
      crossOrigin: 'anonymous',
    },
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700;900&display=swap',
    },
  ];
};

export const headers: HeadersFunction = () => ({
  'X-Content-Type-Options': 'nosniff',
});

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const session = await getSession(request.headers.get('Cookie'));
  const csrfToken = session.get('csrf') || createCSRFToken();
  session.set('csrf', csrfToken);

  const [user, userInfo] = await Promise.all([
    getUser(request),
    getUserInfo(request),
  ]);

  setContext('Server Error Context', {
    user: {
      id: user?.id,
      email: user?.email,
      fullName: user?.fullName,
      type: user?.type,
    },
  });

  return Response.json(
    {
      ENV: getClientEnv(),
      user,
      userInfo: userInfo && Object.keys(userInfo).length ? userInfo : null,
      csrfToken,
    },
    {
      headers: {
        'Cache-Control': 'public, max-age=3600',
        'Set-Cookie': await commitSession(session),
      },
    },
  );
};

export const action = async () => null;

const Container = styled.div`
  color: ${({ theme }) => theme.colors.black10};
  font-family: 'Noto Sans', sans-serif;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  min-height: 100vh;
`;

const GlobalStyles = createGlobalStyle`
  html, body {
    height: 100%;
  }
`;

export function Layout({ children }: { children: React.ReactNode }) {
  const nonce = useNonce();
  const data = useRouteLoaderData<typeof loader>('root');

  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
      </head>
      <body>
        <GlobalStyles />
        {children}
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)};`,
          }}
          nonce={nonce}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
            window.intercomSettings = {
              app_id: '${ENV.INTERCOM_APP_ID}',
              autoBoot: '${Boolean(data?.user?.segmentEnabled && data?.userInfo)}',
              custom_launcher_selector: '.open-intercom-messenger',
              ...${JSON.stringify(data?.userInfo)},
            };`,
          }}
          nonce={nonce}
        />
        {/* The way to pass the nonce to the styled-components style tag https://github.com/styled-components/styled-components/issues/4258#issuecomment-2448686921 */}
        <script
          dangerouslySetInnerHTML={{
            __html: `window.__webpack_nonce__ = '${nonce}';`,
          }}
          nonce={nonce}
        />
      </body>
    </html>
  );
}

function App() {
  const { user, ENV } = useLoaderData<typeof loader>();

  const { boot: bootMetaPixel } = useMetaPixel(ENV.META_PIXEL_ID);

  useEffect(() => {
    if (user?.segmentEnabled) bootMetaPixel({ userEmail: user?.email });
  }, [user, bootMetaPixel]);

  return (
    <StyleSheetManager
      shouldForwardProp={(prop, target) =>
        typeof target !== 'string' ||
        isPropValid(prop) ||
        prop === 'fetchPriority'
      }
    >
      <Container role="document">
        <Outlet />
      </Container>
    </StyleSheetManager>
  );
}

export default withTheme(withSentry(App), { useGlobalStyles: true });

export function RootErrorBoundary() {
  const error = useRouteError();
  const data = useRouteLoaderData<typeof loader>('root');

  const { id, email, fullName, type } = data?.user || {};
  const isAuth = Boolean(id);

  setContext('Error Boundary Context', {
    user: {
      id,
      email,
      fullName,
      type,
    },
    'Server Side': isRouteErrorResponse(error),
  });
  captureRemixErrorBoundaryError(error);

  return (
    <ErrorLayout
      headerProps={{
        id: id?.toString(),
        userEmail: email,
        userName: fullName,
        userType: type as TUserRole,
      }}
      isAuth={isAuth}
      showBanner
    >
      <ErrorBoundaryHN error={error} />
    </ErrorLayout>
  );
}

export const ErrorBoundary = withTheme(RootErrorBoundary, {
  useGlobalStyles: true,
});

export const shouldRevalidate = () => false;
